import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"

const Navigation = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            navigationLinks {
              text
              href
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="links">
          {data.site.siteMetadata.navigationLinks.map(link => (
            <Link
              to={link.href}
              className="link"
              activeClassName="active"
            >
              {link.text}
            </Link>
          ))}
        </div>
      )
    }}
  />
);

Navigation.propTypes = {
}

Navigation.defaultProps = {
}

export default Navigation
