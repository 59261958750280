import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"

function Tagline() {
  return (
    <h1 className="tagline">
      <StaticQuery
        query={graphql`
          query {
            placeholderImage: file(relativePath: { eq: "serious_play.png" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 3000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => <Img className="pure-u-md-1-2 pure-u-1-1" fluid={data.placeholderImage.childImageSharp.fluid}></Img>}
      />
      <div class="pure-u-md-1-2 pure-u-1-1">
        Fitness, productivity and educational
        <br />
        apps and games
      </div>
    </h1>
  )
}
export default Tagline
