import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../components/logo"
import Navigation from "../components/navigation"
import Tagline from "../components/tagline"

const Header = ({ siteTitle, graphic = false }) => (
  <div className={`headerContainer pure-g ${graphic ? 'topImage' : 'noTopImage'}`}>
    <div className="imageContainer pure-u-1-1">
      {graphic ? (
        <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(relativePath: { eq: "header_2022.png" }) {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 3000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
          render={data => <Img className="image" fluid={data.placeholderImage.childImageSharp.fluid}></Img>}
        />
      ) : null}
      <header className="header pure-u-1-1">
        <div className="nav">
          <div className="pure-u-md-1-8 pure-u-1-2">
            <Link
              to="/"
              className="logoLink"
            >
              <Logo graphic={graphic} />
            </Link>
          </div>
          <div className="pure-u-md-7-8 pure-u-1-2">
            <Navigation />
          </div>
        </div>
        {graphic ? (
          <div className="taglineContainer">
            <Tagline />
          </div>
        ) : null}
      </header>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
