import React from "react"

const Footer = () => (
  <div className="footer pure-g">
    <div className="pure-u-md-1-2 pure-u-1-1 text-left">
      <div className="text">
        Copyright <span className="bold">© KickCode Ltd {new Date().getFullYear()}</span>
      </div>

      <div className="text">
        Company Registration <span className="bold">07360604</span>
      </div>
    </div>

    <div className="pure-u-md-1-2 pure-u-1-1 text-right m-text-left">
      <a className="text" href="https://twitter.com/kickcode">
        @KickCode
      </a>
      <br />
      <a className="text" href="mailto:contact@kickcode.com">
        contact@kickcode.com
      </a>
    </div>
  </div>
)

export default Footer
